import React from "react";
import "./styles.css";
import Tab from "./tab";

function App() {
  return (
    <div className="App">
      <h1 className="topname"> WELCOME </h1>
      <Tab />
    </div>
  );
}

export default App;
